<template>
  <div>
    <el-card>
      <el-row>
        <el-col :span="16">
          <el-form :inline="true" :model="formData">
            <el-form-item :label="$t('pigeonGuild.searchPigeonGuild')">
              <el-input v-model="formData.name" :placeholder="$t('pigeonGuild.msg.inputNamePrompt')"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmitSearchFormData">{{$t('pigeonGuild.btns.search')}}</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8" type="flex" :align="'end'" justify="center">
          <el-button type="primary" @click="clickCreateButton">{{$t('pigeonGuild.createPigeonGuild')}}</el-button>
        </el-col>
      </el-row>
      <el-table ref="filterTable" :data="showDataList" stripe :border="true" style="width: 100%">
        <el-table-column prop="index" :label="$t('common.sn')" width="80"></el-table-column>
        <el-table-column prop="loginName" :label="$t('pigeonGuild.pigeonGuildName')" width="120"></el-table-column>
        <!-- <el-table-column prop="communityContact" :label="$t('pigeonGuild.pigeonGuildContact')" width="120"></el-table-column>
        <el-table-column prop="communityTel" :label="$t('pigeonGuild.pigeonGuildTel')" width="120"></el-table-column>
        <el-table-column prop="communityAddr" :label="$t('pigeonGuild.pigeonGuildAddress')" width="120"></el-table-column>
        <el-table-column prop="communityRemark" :label="$t('pigeonGuild.remark')" width="120"></el-table-column> -->
        <el-table-column :label="$t('common.operation')">
          <template slot-scope="scope">
            <el-button type="primary" @click="clickManageButton(scope.row)">{{$t('common.manage')}}</el-button>
            <!-- <el-button type="warning" @click="clickEditButton(scope.row)">{{$t('common.edit')}}</el-button> -->
            <el-button type="danger" v-if="pwdType === '2'" @click="clickDeleteButton(scope.row)">{{$t('common.delete')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pagination.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.totalData">
      </el-pagination>
    </div>
    <el-dialog :title="guildForm.title" :visible.sync="dialogFormVisible">
        <el-form ref="form" :model="guildForm" label-width="120px">
        <el-form-item :label="$t('pigeonGuild.pigeonGuildName')">
          <el-input v-model="guildForm.name" :placeholder="$t('pigeonGuild.msg.inputNamePrompt')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pigeonGuild.pigeonGuildPassword')">
          <el-input v-model="guildForm.password" :placeholder="$t('pigeonGuild.msg.inputPasswordPrompt')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pigeonGuild.passwordConfirm')">
          <el-input v-model="guildForm.passwordConfirm" :placeholder="$t('pigeonGuild.msg.hintPasswordConfirm')"></el-input>
        </el-form-item>
        <!-- <el-form-item :label="$t('pigeonGuild.pigeonGuildContact')">
          <el-input v-model="guildForm.contact" :placeholder="$t('pigeonGuild.msg.inputContactPrompt')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pigeonGuild.pigeonGuildTel')">
          <el-input v-model="guildForm.tel" :placeholder="$t('pigeonGuild.msg.inputTelPrompt')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pigeonGuild.pigeonGuildAddress')">
          <el-input v-model="guildForm.address" :placeholder="$t('pigeonGuild.msg.inputAddressPrompt')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('pigeonGuild.remark')">
          <el-input v-model="guildForm.remark" :placeholder="$t('pigeonGuild.msg.inputRemarkPrompt')"></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="clickDialogConfirmButton">{{$t("common.confirm")}}</el-button>
          <el-button @click="dialogFormVisible = false">{{$t("common.cancel")}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {queryRolesList, registerAgent, deleteAgent} from '@/api/requestUtils'
export default {
  data () {
    return {
      formData: {
        name: ''
      },
      guildForm: {
        title: "",
        guildId: -1,
        name: "",
        password: "",
        passwordConfirm: "",
        contact: "",
        tel: "",
        address: "",
        remark: ""
      },
      dataList: [],
      showDataList: [],
      pagination: {
        pageSize: 10,
        currentPage: 1,
        totalData: 0
      },
      dialogFormVisible: false,
    }
  },
  mounted () {
    this.onQueryPigeonGuildList()
  },
  methods: {
    onSubmitSearchFormData () {
      if (this.formData.name && this.formData.name.trim()) {
        this.showDataList = []
        this.dataList.forEach(item => {
          if (item.loginName.includes(this.formData.name)) {
            this.showDataList.push(item)
          }
        })
      } else {
        this.showDataList = this.dataList.slice(this.pagination.pageSize * (this.pagination.currentPage - 1), this.pagination.pageSize * this.pagination.currentPage)
      }
    },
    handleSizeChange (pageSize) { // 分页:单页最大数量变化时的处理
      this.pagination.currentPage = 1
      this.pagination.pageSize = pageSize
      this.showDataList = this.dataList.slice(0, pageSize)
    },
    handleCurrentChange (currentPage) { // 分页:当前页数变化时的处理
      this.pagination.currentPage = currentPage
      this.showDataList = this.dataList.slice((currentPage - 1) * this.pagination.pageSize, currentPage * this.pagination.pageSize)
    },
    clickCreateButton () {
      this.guildForm = {
        title: this.$t('pigeonGuild.createPigeonGuild'),
        guildId: -1,
        name: "",
        password: "",
        passwordConfirm: "",
        contact: "",
        tel: "",
        address: "",
        remark: ""
      }
      this.dialogFormVisible = true
    },
    clickManageButton (data) {
      let routeData = this.$router.resolve({
        path: '/competition/pigeonHouse'
      })
      window.sessionStorage.setItem('guildId', data.userId)
      window.sessionStorage.setItem("guildName", data.loginName)
      window.open(routeData.href, '')
    },
    clickEditButton (data) {
      this.guildForm = {
        title: this.$t('pigeonGuild.editPigeonGuild'),
        guildId: data.id,
        name: data.communityName,
        contact: data.communityContact,
        tel: data.communityTel,
        address: data.communityAddr,
        remark: data.communityRemark
      }
      this.dialogFormVisible = true
    },
    clickDeleteButton (data) {
      this.showConfirmDialog(this.$t('common.notice'), this.$t('pigeonGuild.msg.deleteTip'), () => {
        deleteAgent(data.userId).then(res => {
          if (res.data.code === 0) {
            this.$message.success(this.$t("common.deleteSuccess"))
                for (let index = 0; index < this.dataList.length; index++) {
                  let item = this.dataList[index]
                  if (item.userId === data.userId) {
                    this.dataList.splice(index, 1)
                    this.pagination.totalData = this.dataList.length
                    break
                  }
                }
                this.showDataList = this.dataList.slice(
                  this.pagination.pageSize * (this.pagination.currentPage - 1),
                  this.pagination.pageSize * this.pagination.currentPage
                )
                if (!this.showDataList.length && this.pagination.currentPage > 1) {
                  this.pagination.currentPage -= 1
                  this.showDataList = this.dataList.slice(this.pagination.pageSize *(this.pagination.currentPage - 1), this.pagination.pageSize * this.pagination.currentPage)
                }
          } else {
            this.$message.error(this.$t('common.deleteFailed'))
          }
        }).catch(e => {})
      })
    },
    clickDialogConfirmButton () {
      let name = this.guildForm.name.trim()
      let password = this.guildForm.password.trim()
      let confirmPassword = this.guildForm.passwordConfirm.trim()
      if (!name) {
        return this.$message.error(this.$t('pigeonGuild.msg.inputNamePrompt'))
      }
      if (password && password.length < 4) {
        return this.$message.error(this.$t('pigeonGuild.msg.inputPasswordPrompt'))
      }
      if (confirmPassword !== password) {
        return this.$message.error(this.$t('pigeonGuild.msg.hintPasswordConfirm'))
      }
    //   if (!this.guildForm.contact || !this.guildForm.contact.trim()) {
    //     return this.$message.error(this.$t('pigeonGuild.msg.inputContactPrompt'))
    //   }
    //   if (!this.guildForm.tel || this.guildForm.tel.trim().length < 3) {
    //     return this.$message.error(this.$t('pigeonGuild.msg.inputTelPrompt'))
    //   }
    //   if (!this.guildForm.address || !this.guildForm.address.trim()) {
    //     return this.$message.error(this.$t('pigeonGuild.msg.inputAddressPrompt'))
    //   }
    //   if (!this.guildForm.remark || !this.guildForm.remark.trim()) {
    //     return this.$message.error(this.$t('pigeonGuild.msg.inputRemarkPrompt'))
    //   }
      this.dialogFormVisible = false
      if(this.guildForm.guildId === -1) {
          this.onInsertPigeonGuild()
      } else {
          this.onUpdatePigeonGuild()
      }
    },
    onQueryPigeonGuildList () {
      queryRolesList(1, '')
        .then((res) => {
          if (res.data.code === 0 && res.data.data) {
            this.dataList = [];
            this.showDataList = [];
            if (res.data.data[0]) {
              res.data.data[0].forEach((item) => {
                item.index = this.dataList.length + 1
                this.dataList.push(item);
              });
            }
            this.pagination.totalData = this.dataList.length;
            this.showDataList = this.dataList.slice(this.pagination.pageSize * (this.pagination.currentPage - 1),this.pagination.pageSize * this.pagination.currentPage);
            if (!this.showDataList.length) {
              this.showDataList = this.dataList.slice(0,this.pagination.pageSize);
            }
          }
        })
        .catch((e) => {})
    },
    onInsertPigeonGuild () {
      let name = this.guildForm.name.trim()
      let password = this.guildForm.password.trim()
      if(password.length === 0){
          password = '123456'
      }
      registerAgent({
            loginName: name,
            userName: name,
            password: password,
            roles: '104'
        }).then((res) => {
          if (res.data.code === 0) {
            this.$message.success(this.$t("common.createSuccess"))
            this.onQueryPigeonGuildList()
          } else if (res.data.code === 3010) {
            this.$message.error(this.$t("errorCode.accountExisted"))
          } else {
            this.$message.error(this.$t("common.createFailed"))
          }
        })
        .catch((e) => {})
    },
    onUpdatePigeonGuild () {
    //   let guildId = this.guildForm.guildId
    //   let name = this.guildForm.name.trim()
    //   let contact = this.guildForm.contact.trim()
    //   let tel = this.guildForm.tel.trim()
    //   let address = this.guildForm.address.trim()
    //   let remark = this.guildForm.remark.trim()
    //   updatePigeonGuild(guildId, name, contact, tel, address, remark).then(res => {
    //     if (res.data.code === 0) {
    //       this.$message.success(this.$t('common.updateSuccess'))
    //       for (let index = 0; index < this.dataList.length; index++) {
    //           let item = this.dataList[index]
    //           if (item.id === guildId) {
    //             item.communityName = name
    //             item.communityContact = contact
    //             item.communityTel = tel
    //             item.communityAddr = address
    //             item.communityRemark = remark
    //             break
    //           }
    //       }
    //     } else {
    //       this.$message.error(this.$t('common.updateFailed'))
    //     }
    //   }).catch(e => {})
    },
    showConfirmDialog (title, content, confirmCallback) {
      this.$confirm(content, title, {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warnning'
      }).then(confirmCallback).catch(() => {})
    }
  }
}
</script>
<style lang="less" scoped>

</style>
